import { isMobile } from '@helpers/mobile';
import { useRoute } from 'vue-router';
import {
    defineNuxtRouteMiddleware,
    navigateTo,
} from 'nuxt/app';

export default defineNuxtRouteMiddleware(async () => {
    const route = useRoute();

    if (isMobile()) {
        return navigateTo({ path: `/ockto/${route.params.hash}` });
    }
});
